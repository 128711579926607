import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Helmet} from 'react-helmet'
import { Link } from 'gatsby'
import LinkArrow from '../images/graphic-elements/link-arrow-blue.svg'


const ContactThanks = () => (
  <>
    <Layout>
      <Helmet>
        <body className="inner-page no-hero" data-active-page="page-contact-us page-contact-us-thanks" />
      </Helmet>
      <SEO title="Contact Us - Thank You" />
      <section className="content-container">
        <h1>Thank You</h1>
        <p>Thank you for contacting us. We'll reply to your query as soon as possible.</p>
        <Link className="page-link" to="/">
          <span className="link-label">Return Home</span>
          <img className="link-icon" src={LinkArrow} alt="" />
        </Link>                  
      </section>
    </Layout>
  </>
)

export default ContactThanks
